import React from 'react';
import './generic.css';
import {
  // Row,
  // Col,
  Container,
  Button,
} from 'reactstrap';
class TitleTextCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container
          fluid={true}
          style={{
            backgroundColor: '#1c1c1c',
            padding: '40px',
            marginTop: '4rem',
            marginBottom: '4rem',
          }}
        >
          <h1
            style={{
              color: '#fff',
              fontFamily: 'Aller',
              marginBottom: '2rem',
              textAlign: 'center',
            }}
          >
            {this.props.title}
          </h1>
          <h2
            style={{
              color: '#fff',
              fontFamily: 'Aller',
              marginBottom: '4rem',
              textAlign: 'center',
            }}
          >
            {this.props.text}
          </h2>
          <div style={{ margin: '0 auto', width: '145px' }}>
            {this.props.callToActionText && <Button id="zetaSectionButton"><a href={this.props.callToActionUrl}>{this.props.callToActionText}</a></Button>}
          </div>
        </Container>
      </div>
    );
  }
}
export default TitleTextCTA;
