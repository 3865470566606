import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import { Row, Col, Container, Button } from 'reactstrap';
import GenericTitleTextCTA from 'components/generic/TitleTextCTA';
import Footer from 'components/footer';


const About = ({ data }) => (
  <Layout>
    <Container
      style={{
        backgroundColor: 'rgb(244,242,239)',
        padding: '20px',
        marginTop: '4rem',
        marginBottom: '4rem',
      }}
    >
      <Row>
        <div
          dangerouslySetInnerHTML={{
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }}
        />
      </Row>

    </Container>
    <GenericTitleTextCTA
      title="Luxurious hotel rooms"
      text="Hotel Grace Galaxy has rooms with great views, bathtubs, in room service and much more"
      callToActionText="Book Now"
      callToActionUrl="/book" />
    <Footer />
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
